import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Image, { IMAGES } from '../components/image'
import DetailText from '../components/detail-text'
import SEO from '../components/seo'

const IndexPage = ({ location }) => (
  <Layout location={location} crumbLabel="404">
    <SEO title="Home" />
    <div
      className="
      grid

      pb-4 sm:pb-0
      sm:col-span-6 sm:grid-cols-2
      md:justify-i-end
      "
    >
      <div className="" />
      <div className="md:max-w-xs w-full">
        <Image filename={IMAGES.sleepyFoxEyeRaiseLogotype} />
      </div>
    </div>
    <div className="
      grid
      sm:col-span-6 sm:grid-cols-6 sm:gap-4
      md:col-span-6 md:grid-cols-2 md:gap-4
      "
    >
      <div className="sm:col-span-5 md:col-span-1">
        <DetailText
          header="404 Not Found"
          body=""
        />
        <DetailText
          header="What now?!"
          body="It looks like that page doesn't exist. Maybe I'll get around to creating it someday?"
        />
      </div>
    </div>
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
